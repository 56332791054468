import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const openAi4Service = createApi({
  reducerPath: "openAi4",
  tagTypes: "openAi4S",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      openAi4HeadlineArray: builder.mutation({
        query: (data) => {
          return {
            url: "api/headlinesGPT4",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["openAi4S"],
      }),
      openAi4DescriptionArray: builder.mutation({
        query: (data) => {
          return {
            url: "api/descriptionGPT4",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["openAi4S"],
      }),
      getBodyData: builder.mutation({
        query: (data) => {
          return {
            url: "api/bodyData",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["openAi4S"],
      }),
    };
  },
});
export const {
  useOpenAi4HeadlineArrayMutation,
  useOpenAi4DescriptionArrayMutation,
  useGetBodyDataMutation,
} = openAi4Service;
export default openAi4Service;
