import React, { useState, useEffect } from "react";
import { json, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Tooltip } from "react-tippy";

import Select from "react-select";
import Papa from "papaparse";
import {
  SearchIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  DownloadIcon,
  DetailIcon,
  EditIcon,
  CloudIcon,
} from "../../assets/Icons";
import {
  setGlobalData,
  setLocalData,
  deleteLocalData,
  setExpendRow,
  setSelectedType,
  setcsvReadedData,
  setLang,
} from "../../store/reducers/globalReducer";
import {
  setWoFileJSON,
  deleteWoFileLocalData,
} from "../../store/reducers/woFileReducer";
import { useGetBodyDataMutation } from "../../store/services/openAi4Service";

import Wrapper from "../../routes/Wrapper";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";

import WoGlobalHeadline from "../../components/WoFileRow/WoGlobalHeadline";
import WoGlobalDescription from "../../components/WoFileRow/WoGlobalDescription";
import WoFileTableRow from "../../components/WoFileRow/WoFileTableRow";

import GlobalHeadline from "../../components/GlobalRow/GlobalHeadline";
import GlobalDescription from "../../components/GlobalRow/GlobalDescription";
import RowExpendHeadline from "../../components/DataTable/RowExpendHeadline";
import RowExpendDescription from "../../components/DataTable/RowExpendDescription";

import TableRowModel from "../../components/TableRowModel";
import UpdateHeadlineModel from "../../components/UpdateHeadlineModel";
import GenerateUrlSummaryModel from "../../components/GenerateUrlSummaryModel";
import GenerateUrlSummaryWoF from "../../components/GenerateUrlSummaryModel/GenerateUrlSummaryWoF";
import { toast } from "react-toastify";

const Main = () => {
  const dispatch = useDispatch();
  const {
    globalData,
    localData,
    expendRow,
    selectedType,
    csvReadedData,
    globalSelectedHeadlines,
    lang,
  } = useSelector((state) => state.globalReducer);

  const { woGlobalFileData, woFileJSON, woFileJSONDownload } = useSelector(
    (state) => state.woFileReducer
  );

  const [mod_localData, setMod_localData] = useState([]);

  const deleteData = () => {
    dispatch(deleteLocalData("localData"));
    dispatch(deleteLocalData("globalData"));
    dispatch(deleteLocalData("woFileData"));
    dispatch(deleteLocalData("csvReadedData"));
    dispatch(deleteLocalData("selectedType"));
    dispatch(deleteLocalData("expendRow"));
    dispatch(deleteLocalData("formInfo"));
    window.location.reload();
  };
  const deleteWoFileData = () => {
    dispatch(deleteWoFileLocalData("woFileJSON"));
    dispatch(deleteWoFileLocalData("woFileJSONDownload"));
    dispatch(deleteWoFileLocalData("woGlobalFileData"));
    dispatch(deleteWoFileLocalData("woGlobalFileForm"));
    dispatch(deleteWoFileLocalData("expendWoRow"));
    dispatch(deleteWoFileLocalData("selectedWoType"));
    dispatch(deleteWoFileLocalData("inputWoDataDetail"));
    window.location.reload();
  };
  const [selectedLang, setSelectedLang] = useState(lang);
  const languageList = [
    { label: "English", value: "english" },
    // { label: "German", value: "german" },
    { label: "German Formal", value: "germanF" },
    { label: "German Informal", value: "germanInF" },
  ];
  const handleLanguage = (e) => {
    setSelectedLang(e);
    dispatch(setLang(e));
  };
  const [userFile, setUserFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const headers = [
    { label: "Campaign", key: "Campaign" },
    { label: "Ad Group", key: "Ad Group" },
    { label: "Ad type", key: "Ad type" },
    { label: "Labels", key: "Labels" },
    { label: "Headline 1", key: "Headline 1" },
    { label: "Headline 1 position", key: "Headline 1 position" },
    { label: "Headline 2", key: "Headline 2" },
    { label: "Headline 2 position", key: "Headline 2 position" },
    { label: "Headline 3", key: "Headline 3" },
    { label: "Headline 3 position", key: "Headline 3 position" },
    { label: "Headline 4", key: "Headline 4" },
    { label: "Headline 4 position", key: "Headline 4 position" },
    { label: "Headline 5", key: "Headline 5" },
    { label: "Headline 5 position", key: "Headline 5 position" },
    { label: "Headline 6", key: "Headline 6" },
    { label: "Headline 6 position", key: "Headline 6 position" },
    { label: "Headline 7", key: "Headline 7" },
    { label: "Headline 7 position", key: "Headline 7 position" },
    { label: "Headline 8", key: "Headline 8" },
    { label: "Headline 8 position", key: "Headline 8 position" },
    { label: "Headline 9", key: "Headline 9" },
    { label: "Headline 9 position", key: "Headline 9 position" },
    { label: "Headline 10", key: "Headline 10" },
    { label: "Headline 10 position", key: "Headline 10 position" },
    { label: "Headline 11", key: "Headline 11" },
    { label: "Headline 11 position", key: "Headline 11 position" },
    { label: "Headline 12", key: "Headline 12" },
    { label: "Headline 12 position", key: "Headline 12 position" },
    { label: "Headline 13", key: "Headline 13" },
    { label: "Headline 13 position", key: "Headline 13 position" },
    { label: "Headline 14", key: "Headline 14" },
    { label: "Headline 14 position", key: "Headline 14 position" },
    { label: "Headline 15", key: "Headline 15" },
    { label: "Headline 15 position", key: "Headline 15 position" },
    { label: "Description 1", key: "Description 1" },
    { label: "Description 1 position", key: "Description 1 position" },
    { label: "Description 2", key: "Description 2" },
    { label: "Description 2 position", key: "Description 2 position" },
    { label: "Description 3", key: "Description 3" },
    { label: "Description 3 position", key: "Description 3 position" },
    { label: "Description 4", key: "Description 4" },
    { label: "Description 4 position", key: "Description 4 position" },
    { label: "Path 1", key: "Path 1" },
    { label: "Path 2", key: "Path 2" },
    { label: "Final URL", key: "Final URL" },
    { label: "Final mobile URL", key: "Final mobile URL" },
    { label: "Tracking template", key: "Tracking template" },
    { label: "Final URL suffix", key: "Final URL suffix" },
    { label: "Custom parameters", key: "Custom parameters" },
    { label: "Campaign Status", key: "Campaign Status" },
    { label: "Ad Group Status", key: "Ad Group Status" },
    { label: "Status", key: "Status" },
    { label: "Approval Status", key: "Approval Status" },
    { label: "Comment", key: "Comment" },
  ];

  const csvReport = {
    data: localData,
    headers: headers,
    filename: "newAdsFile.csv",
  };

  useEffect(() => {
    if (localData?.length) {
      setUserFile(localData);
    }
  }, [localData]);

  const [selectedMethod, setSelectedMethod] = useState("wof");
  const [selectWoFileBox, setSelectWoFileBox] = useState("headline");
  const [selectedGlobalBox, setSelectedGlobalBox] = useState("headline");
  const [globalExpend, setGlobalExpend] = useState(false);
  const [woFileExpend, setWoFileExpend] = useState(true);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [open, setOpen] = useState(false);
  const [openGenerateUrl, setOpenGenerateUrl] = useState(false);
  const [openWofGenerateUrl, setOpenWofGenerateUrl] = useState(false);

  const campaignList = [
    { display: "Campaign 1", value: "campaign1" },
    { display: "Campaign 2", value: "campaign2" },
    { display: "Campaign 3", value: "campaign3" },
    { display: "Campaign 4", value: "campaign4" },
    { display: "Campaign 5", value: "campaign5" },
  ];
  const adGroup = [
    { display: "AdGroup 1", value: "adGroup1" },
    { display: "AdGroup 2", value: "adGroup2" },
    { display: "AdGroup 3", value: "adGroup3" },
    { display: "AdGroup 4", value: "adGroup4" },
    { display: "AdGroup 5", value: "adGroup5" },
  ];

  const [selectedHeadlineVal, setSelectedHeadlineVal] = useState();
  const [modelData, setModelData] = useState();
  const [modelWofData, setModelWofData] = useState();
  const [selectedHeadlines, setselectedHeadlines] = useState({});
  const [selectedDescriptions, setselectedDescriptions] = useState({});

  const [localDataHeadingkeys, setlocalDataHeadingkeys] = useState([]);
  const [localDataDescriptionkeys, setlocalDataDescriptionkeys] = useState([]);

  const validatevalue = (val) => {
    return (
      val != " -" && val != " --" && val != "" && val != " -" && val != " "
    );
  };

  useEffect(() => {
    let headings = [];
    let descriptions = [];
    let mod_selectedHeadlines = {};
    let mode_selectedDescriptions = {};
    // console.log("csvReadedData_called");
    csvReadedData?.map((mainitem, index) => {
      Object.keys(csvReadedData[index])?.map((item) => {
        if (item?.includes("Headline") && !item?.includes("position")) {
          if (mod_selectedHeadlines[`row${index}`]) {
            if (validatevalue(csvReadedData[index][item])) {
              if (mod_selectedHeadlines[`row${index}`].length < 15) {
                mod_selectedHeadlines[`row${index}`]?.push({
                  Headline: csvReadedData[index][item],
                });
              }
            }
          } else {
            if (validatevalue(csvReadedData[index][item])) {
              mod_selectedHeadlines[`row${index}`] = [
                { Headline: csvReadedData[index][item] },
              ];
            }
          }
          headings.push(csvReadedData[index][item]);
        } else if (
          item?.includes("Description") &&
          !item?.includes("position")
        ) {
          if (mode_selectedDescriptions[`row${index}`]) {
            if (validatevalue(csvReadedData[index][item])) {
              if (mode_selectedDescriptions[`row${index}`].length < 4) {
                mode_selectedDescriptions[`row${index}`]?.push({
                  Description: csvReadedData[index][item],
                });
              }
            }
          } else {
            if (validatevalue(csvReadedData[index][item])) {
              mode_selectedDescriptions[`row${index}`] = [
                { Description: csvReadedData[index][item] },
              ];
            }
          }
          descriptions.push(csvReadedData[index][item]);
        }
      });
      // console.log("headings", headings);
      setselectedHeadlines({ ...mod_selectedHeadlines });
      setselectedDescriptions({ ...mode_selectedDescriptions });
    });
    setlocalDataHeadingkeys(headings);
    setlocalDataDescriptionkeys(descriptions);
  }, [csvReadedData]);

  const cleanDefaultKeys = (data) => {
    let the_data = [];
    data?.map((item, index) => {
      let an_array = {};
      Object.keys(data[index])?.map((item) => {
        if (!item?.includes("Headline") && !item?.includes("Description")) {
          an_array[item] = data[index]?.[item];
        }
      });
      the_data.push(an_array);
    });
    // console.log("final_the_dataa", the_data);
    return the_data;
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const prepare_csv_data_to_download = () => {
    let the_data = [...localData];
    the_data = cleanDefaultKeys(the_data);
    the_data?.map((item, index) => {
      if (selectedHeadlines?.[`row${index}`]) {
        selectedHeadlines?.[`row${index}`]?.map((item, indx) => {
          the_data[index] = {
            ...the_data[index],
            [`Headline ${indx + 1}`]: item?.Headline,
            [`Headline ${indx + 1} position`]:
              localData[index]?.[
                `${getKeyByValue(localData[index], item?.Headline)} position`
              ],
          };
        });
      }
      if (selectedDescriptions?.[`row${index}`]) {
        selectedDescriptions?.[`row${index}`]?.map((item, indx) => {
          the_data[index] = {
            ...the_data[index],
            [`Description ${indx + 1}`]: item?.Description,
            [`Description ${indx + 1} position`]:
              localData[index]?.[
                `${getKeyByValue(localData[index], item?.Description)} position`
              ],
          };
        });
      }
    });

    setMod_localData([...the_data]);
  };

  const TableRow = ({ item, index }) => {
    // console.log("what is tableRow item...", index, item);
    const [openUpdateHeadline, setOpenUpdateHeadline] = useState(false);

    const [selectedStyle, setSelectedStyle] = useState(null);
    const StyleList = [
      {
        display: "Choose Style 1",
        value: "style1",
      },
      {
        display: "Choose Style 2",
        value: "style2",
      },
      {
        display: "Choose Style 3",
        value: "style3",
      },
      {
        display: "Choose Style 4",
        value: "style4",
      },
      {
        display: "Choose Style 5",
        value: "style5",
      },
    ];

    // console.log("selectedHeadlines_main", selectedHeadlines);

    const countHeadlines2 = (item) => {
      let filteredHeadlines = [];
      let notEmpty;
      for (let i = 0; i < Object?.keys(item)?.length; i++) {
        filteredHeadlines.push(item[`Headline ${i}`]);
      }
      if (filteredHeadlines?.length) {
        notEmpty = filteredHeadlines?.filter((el) => {
          if (!el || el == " --") {
            return null;
          } else {
            return el;
          }
        });
      }
      return notEmpty;
    };

    const countDescription2 = (item) => {
      let filteredDescription = [];
      let notEmpty;
      for (let i = 0; i < Object?.keys(item)?.length; i++) {
        filteredDescription.push(item[`Description ${i}`]);
      }
      if (filteredDescription?.length) {
        notEmpty = filteredDescription?.filter((el) => {
          if (!el || el == " --" || el == "-- " || el == "--") {
            return null;
          } else {
            return el;
          }
        });
      }
      return notEmpty;
    };

    return (
      <>
        {item?.["Ad Group"] !== "" &&
        item?.["ad group"] !== "" &&
        item?.["Ad status"] !== "" &&
        item?.["Campaign"] !== "" ? (
          <div
            key={index}
            className={`main-table-row flex flex-col ${
              expendRow.status && expendRow.index === index ? "expend" : ""
            } `}
          >
            <div className={`tbl-row flex items-center cursor-pointer `}>
              <div className="row-item flex items-start justify-center flex-col">
                {item?.["Campaign"] ? (
                  <div className="flex items-center">
                    <h1 className="tag text-black">{item?.["Campaign"]}</h1>
                  </div>
                ) : null}
                {item?.["campaign"] ? (
                  <div className="flex items-center">
                    <h1 className="tag  text-black">{item?.["campaign"]}</h1>
                  </div>
                ) : null}
              </div>
              <div className="row-item flex items-start justify-center flex-col">
                {item?.["Ad Group"] ? (
                  <div className="flex items-center">
                    <h1 className="tag  text-black">{item?.["Ad Group"]}</h1>
                  </div>
                ) : null}
                {item?.["Ad group"] ? (
                  <div className="flex items-center">
                    <h1 className="tag  text-black">{item?.["Ad group"]}</h1>
                  </div>
                ) : null}
                {item?.["ad group"] ? (
                  <div className="flex items-center">
                    <h1 className="tag  text-black">{item?.["ad group"]}</h1>
                  </div>
                ) : null}
              </div>
              <div className="row-item flex flex-col items-start">
                <div className="item flex items-center">
                  <button
                    onClick={(e) => {
                      dispatch(
                        setSelectedType({
                          lbl: "headline",
                          index: index,
                        })
                      );
                    }}
                    className={`cleanbtn radio-btn rel ${
                      (selectedType.lbl === "headline") &
                      (selectedType.index === index)
                        ? "on"
                        : ""
                    }`}
                  />
                  <div className="toggle-desc ml-2">
                    <div className="mr-2">{countHeadlines2(item)?.length}</div>
                    {countHeadlines2(item)?.length > 1
                      ? "Headlines"
                      : "Headline"}
                  </div>
                </div>
                <div className="item flex items-center">
                  <button
                    onClick={(e) => {
                      dispatch(
                        setSelectedType({
                          lbl: "description",
                          index: index,
                        })
                      );
                    }}
                    className={`cleanbtn radio-btn rel ${
                      selectedType.lbl === "description" &&
                      selectedType.index === index
                        ? "on"
                        : ""
                    }`}
                  />
                  <div className="toggle-desc ml-2">
                    <div className="mr-2">
                      {countDescription2(item)?.length}
                    </div>
                    {countDescription2(item)?.length > 1
                      ? "Descriptions"
                      : "Description"}
                  </div>
                </div>
              </div>
              <div className="row-item flex items-center justify-end">
                <div className="icons-actions flex items-center">
                  {/* <div
                    className="cloud-icon icon flex items-center justify-center relative"
                    onClick={(e) => {
                      setOpenGenerateUrl(true);
                      setModelData({ item, index });
                    }}
                  >
                    <Tooltip
                      title="Generate Description from Website"
                      position="top"
                      arrow="true"
                      animation="scale"
                    >
                      <CloudIcon />
                    </Tooltip>
                  </div> */}

                  <div
                    className="detail-icon icon flex items-center justify-center"
                    onClick={(e) => {
                      setOpen(true);
                      setModelData({ item, index });
                    }}
                  >
                    <Tooltip
                      title="See More Detail"
                      position="top"
                      arrow="true"
                      animation="scale"
                    >
                      <DetailIcon />
                    </Tooltip>
                  </div>

                  <div
                    id="expend-icon"
                    className="arrow-icon icon flex items-center justify-center"
                    onClick={(e) => {
                      if (
                        expendRow?.status === true &&
                        expendRow?.index === index
                      ) {
                        dispatch(
                          setExpendRow({
                            status: false,
                            index: "",
                          })
                        );
                      } else {
                        dispatch(
                          setExpendRow({
                            status: true,
                            index: index,
                          })
                        );
                        dispatch(
                          setSelectedType({
                            lbl: "headline",
                            index: index,
                          })
                        );
                      }
                    }}
                  >
                    {expendRow.status && expendRow.index === index ? (
                      <Tooltip
                        title="Minimize"
                        position="top"
                        arrow="true"
                        animation="scale"
                      >
                        <ArrowUpIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Expend"
                        position="top"
                        arrow="true"
                        animation="scale"
                      >
                        <ArrowDownIcon />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {expendRow.status && expendRow.index === index && (
              <>
                {(selectedType.lbl === "headline") &
                (selectedType.index == index) ? (
                  <>
                    {countHeadlines2(item)?.length ? (
                      <RowExpendHeadline
                        item={item}
                        index={index}
                        setExpendRow={setExpendRow}
                        setselectedHeadlines={setselectedHeadlines}
                        selectedHeadlines={selectedHeadlines}
                        localDataHeadingkeys={localDataHeadingkeys}
                        globalSelectedHeadlines={globalSelectedHeadlines}
                        setOpenGenerateUrl={setOpenGenerateUrl}
                        setModelData={setModelData}
                      />
                    ) : null}
                  </>
                ) : (selectedType.lbl === "description") &
                  (selectedType.index == index) ? (
                  <>
                    {countDescription2(item)?.length ? (
                      <RowExpendDescription
                        item={item}
                        index={index}
                        setExpendRow={setExpendRow}
                        setselectedDescriptions={setselectedDescriptions}
                        selectedDescriptions={selectedDescriptions}
                        localDataDescriptionkeys={localDataDescriptionkeys}
                        setOpenGenerateUrl={setOpenGenerateUrl}
                      />
                    ) : null}
                  </>
                ) : null}
              </>
            )}
          </div>
        ) : null}
        <Modal
          open={openUpdateHeadline}
          onClose={() => setOpenUpdateHeadline(false)}
        >
          <UpdateHeadlineModel
            setOpen={setOpenUpdateHeadline}
            selectedHeadlineVal={selectedHeadlineVal}
            setSelectedHeadlineVal={setSelectedHeadlineVal}
          />
        </Modal>
      </>
    );
  };

  const UploadFile = () => {
    if (uploadedFile) {
      Papa.parse(uploadedFile[0], {
        header: true,
        complete: function (results) {
          dispatch(setLocalData(results.data));
          dispatch(setcsvReadedData(results.data));
        },
      });
    }
  };

  const DemoFile = () => {
    const link = document.createElement("a");
    link.href = DemoFile;
    link.download = "Demo.csv";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const csvData = woFileJSONDownload.map((item) => {
    const csvItem = {
      Campaign: item.campaign,
      "Ad Group": "",
      "Ad type": item.adType,
      Labels: item.labels,
      "Path 1": item.path1,
      "Path 2": item.path2,
      "Final URL": item.finalURL,
      "Final mobile URL": item.finalMobileURL,
      "Tracking template": "",
      "Final URL suffix": "",
      "Custom parameters": "",
      "Campaign Status": item.campaignStatus,
      "Ad Group Status": item.adGroupStatus,
      Status: item.status,
      "Approval Status": item.approvalStatus,
      Comment: "",
    };
    item.headlines.slice(0, 15).forEach((headline, index) => {
      csvItem[`Headline ${index + 1}`] = headline.headline;
      csvItem[`Headline ${index + 1} position`] = headline.position;
    });
    item.descriptions.slice(0, 4).forEach((description, index) => {
      csvItem[`Description ${index + 1}`] = description.description;
      csvItem[`Description ${index + 1} position`] = description.position;
    });

    return csvItem;
  });
  const fileName = "RSAProDownloadFile";
  function exportToCSV(csvData, fileName) {
    if (woFileJSONDownload?.length) {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      toast.warning("Please first select headlines and descriptions first!");
    }
  }

  const [urlData, response] = useGetBodyDataMutation();
  console.log("what is getBodyData response...", response);
  useEffect(() => {
    urlData({ url: "https://www.zeezsoft.com" });
  }, []);

  return (
    <Wrapper>
      <div className="lading-page bg-themeColor2 h-screen flex">
        <div className="wrap wrapWidth flex flex-col">
          <div className="page-title flex items-center justify-center">
            <div className="page-title-tag">
              Google Ads Headline and Description Generator
            </div>
          </div>
          <div className="page-tabs flex items-center justify-center">
            <div className="row3 w-full h-full">
              <div
                className={`tab-item flex ${
                  selectedMethod === "wof" ? "active" : ""
                }`}
                onClick={(e) => setSelectedMethod("wof")}
              >
                Generate ad copy now!
                <div className="tab-item-desc mt-2">Try it out</div>
              </div>
              <div
                className={`tab-item flex ${
                  selectedMethod === "csv" ? "active" : ""
                }`}
                onClick={(e) => setSelectedMethod("csv")}
              >
                Generate ad copy
                <div className="tab-item-desc mt-2">
                  From your existing ads (with Editor CSV Upload)
                </div>
              </div>
              <Tooltip
                title="Coming Soon"
                position="top"
                arrow="true"
                animation="scale"
              >
                <div
                  className={`tab-item flex ${
                    selectedMethod === "api" ? "active" : ""
                  }`}
                  // onClick={(e) => setSelectedMethod("api")}
                >
                  <div className="tab-item-desc">Coming Soon</div>
                  Generate ad copy
                  <div className="tab-item-desc">
                    From your existing ads (with Google Ads API)
                  </div>
                </div>
              </Tooltip>
            </div>
            {/* <div className="row-item flex items-start">
              <div
                className="item flex items-center mr-9 cursor-pointer"
                onClick={(e) => setSelectedMethod("woFile")}
              >
                <button
                  className={`cleanbtn radio-btn rel ${
                    selectedMethod === "woFile" ? "on" : ""
                  }`}
                />
                <div className="toggle-desc ml-2">Without File Upload</div>
              </div>
              <div
                className="item flex items-center cursor-pointer"
                onClick={(e) => setSelectedMethod("wFile")}
              >
                <button
                  className={`cleanbtn radio-btn rel ${
                    selectedMethod === "wFile" ? "on" : ""
                  }`}
                />
                <div className="toggle-desc ml-2">With File Upload</div>
              </div>
            </div> */}
          </div>
          <div className="page-header flex items-center">
            <div className="row flex flex-col w-full">
              <div className="file-action flex items-center w-full">
                <div className="input-field flex flex-col">
                  <div className="field-tag mb-2 text-sm font-semibold">
                    Choose Language
                  </div>
                  <Select
                    defaultValue={selectedLang}
                    value={selectedLang}
                    onChange={handleLanguage}
                    options={languageList}
                    className="select w-full"
                    name="language"
                    placeholder="Choose Language"
                  />
                </div>
              </div>
            </div>
          </div>
          {selectedMethod === "wof" ? (
            <>
              <div className="page_block flex flex-col">
                <div className="table-section flex flex-col">
                  <div className="tbl-sec-hdr flex items-center justify-between">
                    <div className="block-tag">
                      Create Ad Copy from Product Name and Description
                    </div>
                  </div>

                  <div className="table_block flex flex-col">
                    {/* Main Table Data */}
                    {woFileJSON?.length ? (
                      <>
                        <div className={`main-table-column flex items-center`}>
                          {/* <div
                            className={`tbl-column flex items-center cursor-pointer w-full`}
                          >
                            <div className="row-item flex items-start justify-center flex-col">
                              <div className="tag ">Campaign</div>
                            </div>
                            <div className="row-item flex items-start justify-center flex-col">
                              <div className="tag">Ad Group</div>
                            </div>
                            <div className="row-item flex items-start justify-center flex-col">
                              <div className="tag">
                                Total Headlines
                                <br />
                                Total Descriptions
                              </div>
                            </div>
                            <div className="row-item flex items-start justify-center flex-col">
                              <div className="tag">Action</div>
                            </div>
                          </div> */}
                        </div>
                        <>
                          {woFileJSON?.map((item, index) => (
                            <WoFileTableRow item={item} index={index} />
                          ))}
                        </>
                      </>
                    ) : (
                      <div className="block-tag flex items-center justify-center py-12">
                        You Still have not add a Table Row!
                      </div>
                    )}
                  </div>
                  <div className="tbl-sec-hdr flex items-center justify-center mt-5">
                    {woFileJSON?.length ? (
                      <div className="row2">
                        <button
                          className="btn button"
                          onClick={() => exportToCSV(csvData, fileName)}
                        >
                          Download CSV File
                        </button>
                        <button
                          className="btn button"
                          onClick={(e) => {
                            deleteWoFileData();
                          }}
                        >
                          Clear Data
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="page-header flex items-center">
                <div className="row flex flex-col">
                  <div className="tag">Add CSV File*</div>
                  <div className="text-sm">
                    Please upload an existing CSV file containing Campaign,
                    Ad-group ,URL, Headline and Description information.
                    <br /> You can use an editor or Google Ads download file{" "}
                    <br />
                    Please remove summary rows such as totals.
                  </div>
                  <input
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    className="select-file max-w-max cursor-pointer"
                    onChange={(e) => {
                      const files = e.target.files;
                      setUploadedFile(files);
                      setUserFile(files[0]);
                    }}
                  />
                  <div className="file-action flex items-center w-full">
                    <button
                      className="btn button mr-5"
                      onClick={(e) => {
                        UploadFile();
                      }}
                    >
                      Upload File
                    </button>
                  </div>
                </div>
              </div>
              <div className="page_block flex flex-col">
                <div className="table-section flex flex-col">
                  <div className="tbl-sec-hdr flex items-center justify-between">
                    <div className="block-tag">
                      Create & Add Assets for Multiple Ads
                    </div>
                  </div>
                  <div className="table_block flex flex-col">
                    {localData?.length ? (
                      <>
                        <div className={`global-row flex flex-col `}>
                          <div
                            className={`global-row-box flex items-center cursor-pointer`}
                          >
                            <div className="row-item table-name flex items-start justify-center flex-col">
                              <div className="tag-lbl">
                                {/* Add Ad Elements to multiple Ad Groups */}
                              </div>
                            </div>
                            <div className="row-item flex items-start">
                              <div
                                className="item flex items-center mr-9"
                                onClick={(e) =>
                                  setSelectedGlobalBox("headline")
                                }
                              >
                                <button
                                  className={`cleanbtn radio-btn rel ${
                                    selectedGlobalBox === "headline" ? "on" : ""
                                  }`}
                                />
                                <div className="toggle-desc ml-2">
                                  Headlines
                                </div>
                              </div>
                              <div
                                className="item flex items-center"
                                onClick={(e) =>
                                  setSelectedGlobalBox("description")
                                }
                              >
                                <button
                                  className={`cleanbtn radio-btn rel ${
                                    selectedGlobalBox === "description"
                                      ? "on"
                                      : ""
                                  }`}
                                />
                                <div className="toggle-desc ml-2">
                                  Descriptions
                                </div>
                              </div>
                            </div>
                            <div className="row-item flex items-center justify-end">
                              <div className="icons-actions flex items-center">
                                {/* <Tooltip
                                  title="Generate Description from Website"
                                  position="top"
                                  arrow="true"
                                  animation="scale"
                                >
                                  <div
                                    className="cloud-icon icon flex items-center justify-center relative mr-2"
                                    onClick={(e) => {
                                      setOpenGenerateUrl(true);
                                      setModelData({ item: "", index: 1190 });
                                    }}
                                  >
                                    <CloudIcon />
                                  </div>
                                </Tooltip> */}
                                <Tooltip
                                  title="Expend"
                                  position="top"
                                  arrow="true"
                                  animation="scale"
                                >
                                  <div
                                    className="arrow-icon icon flex items-center justify-center"
                                    onClick={(e) => {
                                      setGlobalExpend(!globalExpend);
                                    }}
                                  >
                                    {globalExpend ? (
                                      <ArrowUpIcon />
                                    ) : (
                                      <ArrowDownIcon />
                                    )}
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          {globalExpend && (
                            <>
                              {selectedGlobalBox === "headline" ? (
                                <GlobalHeadline
                                  item={globalData.headlines}
                                  setOpenGenerateUrl={setOpenGenerateUrl}
                                  setModelData={setModelData}
                                />
                              ) : selectedGlobalBox === "description" ? (
                                <GlobalDescription
                                  item={globalData.description}
                                  setOpenGenerateUrl={setOpenGenerateUrl}
                                  setModelData={setModelData}
                                />
                              ) : null}
                            </>
                          )}
                        </div>
                        <div className="tbl-sec-hdr mt-12 flex items-center justify-between">
                          <div className="block-tag">
                            Create & Add Assets for Specific Ads
                          </div>
                        </div>
                        <div className={`main-table-column flex items-center`}>
                          <div
                            className={`tbl-column flex items-center cursor-pointer w-full`}
                          >
                            <div className="row-item flex items-start justify-center flex-col">
                              <div className="tag ">Campaign</div>
                            </div>
                            <div className="row-item flex items-start justify-center flex-col">
                              <div className="tag">Ad Group</div>
                            </div>
                            <div className="row-item flex items-start justify-center flex-col">
                              <div className="tag">
                                Total Headlines
                                <br />
                                Total Descriptions
                              </div>
                            </div>
                            <div className="row-item flex items-start justify-center flex-col">
                              <div className="tag">Action</div>
                            </div>
                          </div>
                        </div>
                        <>
                          {localData?.map((item, index) => (
                            <TableRow item={item} index={index} />
                          ))}
                        </>
                      </>
                    ) : (
                      <>
                        <div className="block-tag flex items-center justify-center">
                          Data not found!
                        </div>
                      </>
                    )}
                  </div>
                  <div className="tbl-sec-hdr flex items-center justify-center mt-5">
                    {localData?.length ? (
                      <div className="row2">
                        <div className="dowload-btn flex items-center">
                          <CSVLink
                            data={mod_localData}
                            headers={headers}
                            filename={"RSAProDownloadFile.csv"}
                            asyncOnClick={true}
                            onClick={(event, done) => {
                              prepare_csv_data_to_download();
                              done();
                            }}
                          >
                            <div className="btn button">Download File</div>
                          </CSVLink>
                        </div>
                        <button
                          className="btn button"
                          onClick={(e) => {
                            deleteData();
                          }}
                        >
                          Clear Data
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <Modal open={open} onClose={() => setOpen(false)}>
          <TableRowModel setOpen={setOpen} modelData={modelData} />
        </Modal>

        <Modal open={openGenerateUrl} onClose={() => setOpenGenerateUrl(false)}>
          <GenerateUrlSummaryModel
            setOpenGenerateUrl={setOpenGenerateUrl}
            modelData={modelData}
          />
        </Modal>
        <Modal
          open={openWofGenerateUrl}
          onClose={() => setOpenWofGenerateUrl(false)}
        >
          <GenerateUrlSummaryWoF
            setOpenGenerateUrl={setOpenWofGenerateUrl}
            modelData={modelWofData}
          />
        </Modal>
      </div>
    </Wrapper>
  );
};

export default Main;
